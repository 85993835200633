import { componentContent } from './types';

export function findComponent(components: componentContent) {
  return (components?.cmsArray ?? []).find(component => component.component === components.name) || null;
}

export function findAllComponents(components: componentContent) {
  return (components?.cmsArray ?? []).filter(component => component.component === components.name);
}

export function filterOutComponent(components: componentContent) {
  return (components?.cmsArray ?? []).filter(component => component.component !== components.name);
}
