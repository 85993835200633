import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=274672ff&scoped=true&"
import script from "./Banner.vue?vue&type=script&lang=ts&"
export * from "./Banner.vue?vue&type=script&lang=ts&"
import style0 from "./Banner.vue?vue&type=style&index=0&id=274672ff&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274672ff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaBackground: require('/opt/build/repo/apps/shop/components/storyblok/MediaBackground/MediaBackground.vue').default})
