































import { computed, defineComponent } from '@vue/composition-api';
import { getAngieUIComponent } from '~/selectors/selectorsMap';
import { findComponent, filterOutComponent } from '~/selectors/selectorsHelpers';
import { getValidCSSUnit } from '~/constants/helpers';
import { IBannerAttrs } from './types';

export default defineComponent({
  name: 'Banner',
  inheritAttrs: false,
  setup(_, context) {
    const getProps = (comp: any) => {
      if (comp.component === 'Experiment') {
        return comp;
      }

      return getAngieUIComponent(comp.component, comp);
    };
    const {
      contentContainer: cmsArray,
      contentPosition,
      contentContainerWidth,
      height,
      backgroundColor = '',
    } = context.attrs as IBannerAttrs;

    return {
      containerStyles: computed(() => ({
        height: getValidCSSUnit(height, 'auto'),
      })),
      bannerContentStyles: computed(() => ({
        width: getValidCSSUnit(contentContainerWidth, 'auto'),
      })),
      filteredComponentList: computed(() => filterOutComponent({
        name: 'MediaBackground',
        cmsArray,
      })?.map((comp) => ({ ...comp, bindings: getProps(comp) }))),
      getMediaBackgroundComponent: computed(() => {
        const foundComponent = findComponent({
          name: 'MediaBackground',
          cmsArray,
        });

        if (!foundComponent) return null;

        return {
          ...foundComponent,
          bindings: getProps(foundComponent),
        };
      }),
      getContentPosition: computed(() => contentPosition || 'center'),
      getBackgroundColor: computed(() => backgroundColor || ''),
    };
  },
});
